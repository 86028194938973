export const GRAPH_CONFIG = {
  GRAPH_ME_ENDPT: 'https://graph.microsoft.com/v1.0/me',
  GRAPH_MAIL_ENDPT: 'https://graph.microsoft.com/v1.0/me/messages',
};

export const IPC_MESSAGES = {
  SHOW_WELCOME_MESSAGE: 'SHOW_WELCOME_MESSAGE',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  GET_PROFILE: 'GET_PROFILE',
  SET_PROFILE: 'SET_PROFILE',
  GET_MAIL: 'GET_MAIL',
  SET_MAIL: 'SET_MAIL',
  REQUEST_TOKEN: 'REQUEST_TOKEN',
  SET_METHOD: 'SET_METHOD',
  ON_TOKEN: 'ON_TOKEN',
  LOGIN_SILENT: 'LOGIN_SILENT',
  CLEAR_STORAGE: 'CLEAR_STORAGE',
  ON_LOGOUT: 'ON_LOGOUT',
  REQUEST_ACCESS_TOKEN: 'REQUEST_ACCESS_TOKEN',
  REQUEST_O365_PHOTO: 'REQUEST_O365_PHOTO',
  LIST_HID_DEVICES: 'LIST_HID_DEVICES',
  ON_CONNECT: 'ON_CONNECT',
  ON_DISCONNECT: 'ON_DISCONNECT',
  DESK_UP: 'DESK_UP',
  DESK_DOWN: 'DESK_DOWN',
  DESK_STOP: 'DESK_STOP',
  CHANGE_SHORTCUTS: 'CHANGE_SHORTCUTS',
  RELOAD_EVENT: 'RELOAD_EVENT',

  OPEN_APP: 'OPEN_APP',
  UPDATE_DATABASE_FROM_DESK: 'UPDATE_DATABASE_FROM_DESK',
  UPDATE_DESK_FROM_DATABASE: '  UPDATE_DESK_FROM_DATABASE',

  GET_SYSTEM_IDLE_TIME: 'GET_SYSTEM_IDLE_TIME',
  GET_SYSTEM_IDLE_STATE: 'GET_SYSTEM_IDLE_STATE',
  GET_SESSION_STATE: 'GET_SESSION_STATE',
  GET_DO_NOT_DISTURB: 'GET_DO_NOT_DISTURB',

  BEFORE_QUIT: 'BEFORE_QUIT',
};
