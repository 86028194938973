/* eslint-disable import/no-extraneous-dependencies */
import { IPC_MESSAGES } from '@/native/auth/auth.constants';
import { ipcRenderer as ipc } from 'electron';
import StreamEvent from '@/classes/device/event/device.event.class';
import ConnectState from '../../singletons/connect.state.singleton';
import UserState from '../../singletons/user.state.singleton';
import DeskConnectWrapper from '../../classes/device/desk.connect.wrapper';

ConnectState.measurementSystem = (UserState && UserState.profile && UserState.profile.MeasurementSystem) || 'METRIC';
ConnectState.sitHeight = (UserState && UserState.profile && UserState.profile.SitHeight) || 639;
ConnectState.standHeight = (UserState && UserState.profile && UserState.profile.StandHeight) || 1500;

const VENDOR_ID_ROL = 10785;

const filterOnVendorId = (id) => (device) => device.vendorId === id;

class ConnectService {
  static init() {
    ipc.on(IPC_MESSAGES.RELOAD_EVENT, () => {
      if (ConnectState) {
        ConnectState.destroy();
      }
    });

    ConnectService.scan();

    ipc.on(IPC_MESSAGES.ON_CONNECT, (ev, _device) => {
      if (ConnectState.selectedDevice) return;
      const rolDesk = new DeskConnectWrapper(_device.vendorId, _device.productId, _device.serialNumber);
      if (rolDesk.device) ConnectService.selectDevice(rolDesk);
    });

    ipc.on(IPC_MESSAGES.ON_DISCONNECT, async (ev, _device) => {
      const isConnected = await ipc.invoke(IPC_MESSAGES.DEVICE_CONNECTED, ConnectState.selectedDevice.serialNumber);
      if (!isConnected) {
        StreamEvent.disconnect(ConnectState.selectedDevice);
        ConnectState.destroy();
      }
    });
  }

  static async scan() {
    try {
      console.log('Scanning');
      if (ConnectState.selectDevice) return;
      const devices = (await ipc.invoke(IPC_MESSAGES.LIST_HID_DEVICES)).filter(filterOnVendorId(VENDOR_ID_ROL));
      console.log('Scanned devices', devices);
      devices.forEach((device) => {
        if (ConnectState.selectDevice) return;
        const rolDesk = new DeskConnectWrapper(device.vendorId, device.productId, device.serialNumber);
        if (rolDesk.device) {
          ConnectService.selectDevice(rolDesk);
        }
      });
    } catch (error) {
      console.log('Error scanning for devices: ', error);
    }
  }

  static selectDevice(device) {
    ConnectState.selectedDevice = device;
  }
}

export default ConnectService;
