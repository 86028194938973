/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
// eslint-disable-next-line import/no-extraneous-dependencies
import { ipcRenderer as ipc } from 'electron';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createDesk } from '@rolergo/desk-control-package-nodejs';
import { IPC_MESSAGES } from '@/native/constants';
import UserState from '@/singletons/user.state.singleton';
// import ExtendedDate from '../extended.date.class';
import ReminderState from '@/singletons/reminder.state.singleton';
import ConnectState from '../../singletons/connect.state.singleton';
import StreamEvent from './event/device.event.class';

const READY_INTERVAL_TIMEOUT = 30000;
const MAX_MODE_REPORT_WAIT_TIME = 5000;
class DeskConnectWrapper {
  constructor(vendorId, productId, serialNumber) {
    this.ready = false;
    this.readyToCommunicate = false;

    /* Desk connect */
    this.device = createDesk(vendorId, productId, serialNumber);

    this.serialNumber = serialNumber;

    /** Functions are called when desk reports data */
    this.device.onPositionReported = this.positionReported.bind(this);
    this.device.onMaxHeightReported = this.maxHeightReported.bind(this);
    this.device.onMinHeightReported = this.minHeightReported.bind(this);
    this.device.onHighLimitReported = this.highLimitReported.bind(this);
    this.device.onLowLimitReported = this.lowLimitReported.bind(this);
    this.device.onOffsetReported = this.offsetReported.bind(this);
    this.device.onUnitReported = this.unitReported.bind(this);
    this.device.onErrorReported = this.errorReported.bind(this);
    this.device.onModeReported = this.modeReported.bind(this);

    this.device.connect();

    this.startModeReportedTimeout();

    this.isAutoMoving = false;
    this.connectionFailed = false;
    this.homing = false;

    this.position = null;
    this.minHeight = null;
    this.maxHeight = null;
    this.lowLimit = null;
    this.highLimit = null;
    this.offset = null;

    this.presence();
  }

  startModeReportedTimeout() {
    this.MODE_REPORTED_TIMEOUT = setTimeout(() => {
      console.log('NO REPORT WAS RECEIVED IN 5s, reconnecting');
      this.device.reconnect();
    }, MAX_MODE_REPORT_WAIT_TIME);
  }

  clearModeReportedTimeout() {
    if (this.MODE_REPORTED_TIMEOUT) {
      clearTimeout(this.MODE_REPORTED_TIMEOUT);
    }
  }

  allValuesHaveBeenReported() {
    return ![this.position, this.offset, this.highLimit, this.lowLimit, this.minHeight, this.maxHeight].includes(null);
  }

  getPosition(unit = 'mm') {
    const positions = {
      mm: this.device.getPosition(true),
      cm: this.device.getPosition(true) / 10,
      in: this.device.getPosition(false),
    };
    return positions[unit];
  }

  /**
   * Sending periodic presence event.
   * Will create a zone state if the Desk Connect is connected.
   */
  presence() {
    /** Sending presence: 1 event */
    const device = this;
    StreamEvent.presence(device);

    /** Schedule (* * * * *) (every 60 seconds) */
    ConnectState.pulsateTimeout = setTimeout(() => this.presence(), ConnectState.pulsateIntervalTimeout);
  }

  moveToPosition(position) {
    if (Math.abs(this.position - position) < 10) {
      return;
    }
    this.isAutoMoving = position;
    this.device.sendCommand(this.device.MOVE_TO_POSITION, { position });

    ReminderState.handlePositionChanged();
  }

  moveUp() {
    this.device.sendCommand(this.device.MOVE_UP);
    ReminderState.handlePositionChanged();
  }

  moveDown() {
    this.device.sendCommand(this.device.MOVE_DOWN);
    ReminderState.handlePositionChanged();
  }

  stop() {
    try {
      this.isAutoMoving = false;
      this.device.sendCommand(this.device.STOP);
    } catch (e) {
      console.warn('Error sending stop command', e);
      this.isAutoMoving = true;
    }
  }

  updateHighLimit(highLimit) {
    const position = Math.min(highLimit, this.maxHeight);
    this.device.sendCommand(this.device.SET_HIGH_LIMIT, { position });
    this.device.sendCommand(this.device.REPORT_HIGH_LIMIT);
  }

  updateLowLimit(lowLimit) {
    const position = Math.max(lowLimit, this.minHeight);

    this.device.sendCommand(this.device.SET_LOW_LIMIT, { position });
    this.device.sendCommand(this.device.REPORT_LOW_LIMIT);
  }

  // Send in 'METRIC' or 'IMPERIAL'
  updateUnit(unit) {
    const units = unit === 'METRIC' ? 0 : 1;
    this.device.updateUnit(units);
  }

  reportPosition() {
    this.device.sendCommand(this.device.REPORT_POSITION);
  }

  reportMode() {
    this.device.sendCommand(this.device.REPORT_MODE);
  }

  reportMaxHeight() {
    this.device.sendCommand(this.device.REPORT_MAX_HEIGHT);
  }

  reportMinHeight() {
    this.device.sendCommand(this.device.REPORT_MIN_HEIGHT);
  }

  reportError() {
    this.device.sendCommand(this.device.REPORT_ERROR);
  }

  reportHighLimit() {
    this.device.sendCommand(this.device.REPORT_HIGH_LIMIT);
  }

  reportLowLimit() {
    this.device.sendCommand(this.device.REPORT_LOW_LIMIT);
  }

  updateOffset(offset) {
    this.device.updateOffset(offset);
  }

  reportOffset() {
    this.device.reportOffset();
  }

  reportUnit() {
    this.device.reportUnit();
  }

  checkPositionTarget() {
    const diffFactor = 5; // mm
    const highLimitMet = (Math.abs(this.position - this.highLimit)) <= diffFactor && (this.isAutoMoving >= this.highLimit);
    const lowLimitMet = (Math.abs(this.position - this.lowLimit)) <= diffFactor && (this.isAutoMoving <= this.lowLimit);
    const isCloseToPosition = Math.abs(this.isAutoMoving - this.position) <= diffFactor;

    if (this.isAutoMoving && (highLimitMet || lowLimitMet || isCloseToPosition)) {
      this.isAutoMoving = false;
    }
  }

  positionReported(position) {
    // console.log('Reporting position: ', position / 10);
    // console.log('Reporting position w offset: ', (this.offset + position) / 10);

    if (this.clearAutoMovingTimeout) clearTimeout(this.clearAutoMovingTimeout);
    this.clearAutoMovingTimeout = setTimeout(async () => {
      this.isAutoMoving = false;
    }, 2000);

    this.checkPositionTarget();

    // AppController.checkCurrentMode(position);
    this.position = position;
  }

  errorReported(error) {
    console.log('Reporting error: ', error);
  }

  maxHeightReported(maxHeight) {
    // console.log('Reporting max height: ', maxHeight);
    this.maxHeight = maxHeight;
  }

  minHeightReported(minHeight) {
    // console.log('Reporting min height: ', minHeight);
    this.minHeight = minHeight;
  }

  highLimitReported(highLimit) {
    // console.log('Reporting high limit: ', highLimit);
    // If high limit is acceptable in relation to max height
    if (!highLimit || highLimit > this.maxHeight) {
      this.highLimit = this.maxHeight;
    } else {
      this.highLimit = highLimit;
    }
  }

  lowLimitReported(lowLimit) {
    // console.log('Reporting low limit: ', lowLimit);
    this.lowLimit = lowLimit;
  }

  offsetReported(offset) {
    // console.log('Reporting offset: ', offset);
    this.offset = offset;
  }

  unitReported(unit) {
    // console.log('Reporting unit: ', unit);
    this.unit = unit;
  }

  modeReported(e, modeAssertionFailed) {
    const { name } = e;
    this.clearModeReportedTimeout();
    // console.log('Reporting mode: ', e);
    if (name === 'ACC_MAIN_STATE_NORMAL_MASTER') {
      this.readyToCommunicate = true;
      this.setUpDesk();
    } else if (name === 'ACC_MAIN_STATE_HOME') {
      this.ready = true;
      this.homing = true;
    } else if (modeAssertionFailed) {
      this.ready = true;
      this.connectionFailed = true;
      console.log('Mode assertion failed');
    } else {
      console.log('Unhandled mode reported. ');
    }
  }

  setUpDesk() {
    // Wait for all values to be reported before setting up desk
    const interval = setInterval((self) => {
      const isReady = self.allValuesHaveBeenReported();
      if (isReady) {
        self.registerShortcuts();
        self.ready = true;
        clearInterval(interval);
      }
    }, 250, this);

    // Clear interval after timeout
    const timeout = setTimeout((self) => {
      if (!self.ready) {
        self.connectionFailed = true;
        self.ready = true;
      }
      clearInterval(interval);
      clearTimeout(timeout);
    }, READY_INTERVAL_TIMEOUT, this);
  }

  close() {
    this.device.close();
  }

  registerShortcuts() {
    // Remove old listeners to stop memory leak
    ipc.removeAllListeners(IPC_MESSAGES.DESK_UP);
    ipc.removeAllListeners(IPC_MESSAGES.DESK_DOWN);
    ipc.removeAllListeners(IPC_MESSAGES.DESK_STOP);

    ipc.on(IPC_MESSAGES.DESK_UP, async () => {
      const canUseShortcuts = UserState.profile.shortcut && !UserState.profile.safecontrol && !UserState.changingShortcuts;
      if (!canUseShortcuts) return;

      const height = UserState.profile.StandHeight;
      this.moveToPosition(height);
    });
    ipc.on(IPC_MESSAGES.DESK_DOWN, async () => {
      const canUseShortcuts = UserState.profile.shortcut && !UserState.profile.safecontrol && !UserState.changingShortcuts;
      if (!canUseShortcuts) return;

      const height = UserState.profile.SitHeight;
      this.moveToPosition(height);
    });
    ipc.on(IPC_MESSAGES.DESK_STOP, async () => {
      const canUseShortcuts = UserState.profile.shortcut && !UserState.profile.safecontrol && !UserState.changingShortcuts;
      if (!canUseShortcuts) return;

      this.stop();
    });
  }
}

export default DeskConnectWrapper;
