/* eslint-disable class-methods-use-this */
import { IPC_MESSAGES } from '@/native/auth/auth.constants';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ipcRenderer as ipc } from 'electron';
import ApplicationState, { STATE_LOGIN } from '../../../singletons/application.state.singleton';
import UserState from '../../../singletons/user.state.singleton';
import {
  presence, presenceGuest, left, mode,
} from '../../../util/api';

class ConnectEvent {
  /** init functions */
  init() {
    console.log('');
  }

  static async presence(device) {
    // If trying to send presence 1 when not connected to the desk return
    const isConnected = await ipc.invoke(IPC_MESSAGES.DEVICE_CONNECTED, device.serialNumber);
    if (!isConnected) { return; }

    // If logged in
    if (ApplicationState.appState > STATE_LOGIN) {
      const payload = [{
        Did: device.serialNumber,
        Username: UserState.username,
        Type: 13,
        Time: new Date().getTime(),
        Source: 'DESKTOP',
        Data: {
          LoginMethod: 'UP',
          Presence: 1,
        },
      }];
      presence(device.serialNumber, payload).then(console.log).catch(console.error);

    // If not logged in send presence as guest
    } else {
      presenceGuest(device.serialNumber).then(console.log).catch(console.error);
    }
  }

  static disconnect(device) {
    if (ApplicationState.appState > STATE_LOGIN) {
      const payload = [{
        Did: device.serialNumber,
        Username: UserState.username,
        Type: 13,
        Time: new Date().getTime(),
        Source: 'DESKTOP',
        Data: {
          LoginMethod: 'UP',
          Presence: 0,
        },
      }];

      left(device.serialNumber, payload).then(console.log).catch(console.error);
    }
  }

  static newMode(id, _mode, position) {
    if (ApplicationState.appState > STATE_LOGIN) {
      const payload = [{
        Did: id,
        Username: UserState.username,
        Type: 14,
        Time: new Date().getTime(),
        Source: 'DESKTOP',
        Data: {
          DeskMode: _mode,
          DeskPosition: position,
        },
      }];

      mode(id, payload).then(console.log).catch(console.error);
    }
  }

  static pulsate() {
    console.log('Pulsate', this);
  }

  static handleEventTimeout() {
    console.log(this);
  }
}

export default ConnectEvent;
